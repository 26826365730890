<template>
  <v-container fluid>
    <v-row>
      <v-dialog v-model="dialog" scrollable width="560">
        <v-card>
          <v-card-title class="pa-0">
            <v-toolbar dark>
              <v-btn icon dark @click="cancel()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>
                Assign to {{ salesRepresentative.name }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on }">
                <v-checkbox
                  v-model="showUnassignedOnly"
                  style="margin-top: 20px; margin-left: 20px;"
                  label="Only show unassigned"
                  @click="refreshFilter()"
                  v-on="on"
                ></v-checkbox>
              </template>
              <span>Uncheck to remove existing assignments</span>
            </v-tooltip>
            <v-tabs>
              <v-tab>Point of Sales</v-tab>
              <v-tab-item>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  solo
                  dense
                  class="ma-4"
                  clearable
                  hide-details
                ></v-text-field>
                <v-data-table
                  style="margin-top: 12px;"
                  dense
                  :headers="posHeaders"
                  :items="pos"
                  v-model="posAssigned"
                  :single-select="false"
                  show-select
                  hide-default-footer
                  :items-per-page="itemsPerPage"
                  item-key="id"
                  :search="search"
                >
                  <template v-slot:[`item.isChecked`]="{ item }">
                    <v-checkbox
                      class="shrink ml-0 mr-0 mt-0 mb-0"
                      dense
                      v-model="item.isChecked"
                      @change="setIsDirty()"
                    ></v-checkbox>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn width="100" @click="save">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<style>
.scroll {
  overflow-y: scroll;
}
</style>
<script>
import api from '@/api.js'
//import helpers from '@/common/helpers.js';

export default {
  name: 'assign-to-sales-representative-dlg',
  // mixins: [msalMixin],
  mounted() {},
  data: () => ({
    itemsPerPage:  -1,
    salesRepresentative: { name: '' },
    dialog: false,
    isDirty: false,
    showUnassignedOnly: false,
    persistedChildren: [],
    posAssigned: [],
    posUnassigned: [],
    pos: [],
    posAll: [],
    posHeaders: [{ text: 'Name', value: 'Name', filterable: true }],
    search: '',
  }),
  methods: {
    async loadData() {
      const me = this
      let res = await api.get('Pos')
      me.posAll = res.data

      res = await api.get('Unassigned/Pos')
      me.posUnassigned = res.data

      res = await api.get(
        `Representative/${me.salesRepresentative.id}/Children`,
      )
      me.persistedChildren = res.data

      me.posAssigned = me.posAll.filter((x) =>
        this.persistedChildren.POS.includes(x.id.toLowerCase()),
      )
      console.log('posAssigned')
      console.log(me.posAssigned)

      me.refreshFilter()
    },

    refreshFilter() {
      const me = this
      me.pos = me.showUnassignedOnly == true ? me.posUnassigned : me.posAll
    },

    async show(salesRepresentative) {
      this.salesRepresentative = salesRepresentative
      await this.loadData()
      this.dialog = true
      this.isDirty = false
    },
    setIsDirty() {
      this.isDirty = true
    },
    async save() {
      const me = this

      const postData = {
        POS: me.posAssigned.map((x) => x.id),
      }
      const url = `Representative/${me.salesRepresentative.id}/Children`
      await api.post(url, postData)
      const res = await api.get(me.salesRepresentative.path)
      me.$emit('dataChanged', { old: me.salesRepresentative, new: res.data })
      this.dialog = false
    },
    cancel() {
      this.dialog = false
    },
  },
}
</script>
